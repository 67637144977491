import { fromHEX, toHEX } from '@admin/helpers/admin/userSettings/coding/HEX'

/**
 * Кодирует UTF-8.
 * @param {string} str
 * @returns {string}
 */
export function encode(str) {
  return toHEX(str)
}

/**
 * Декодирует в UTF-8.
 * @param {string} str
 * @returns {string}
 */
export function decode(str) {
  return fromHEX(str)
}
